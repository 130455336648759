<template>
	<div>
		<a-tabs v-model="orderstatus" type="card" @change="tabsChange">
			<a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title">
			</a-tab-pane>
		</a-tabs>
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;" ref="table">
			<!-- <AdvanceTable :columns="columns" :data-source="list" :title="tableTitle" :loading="loading"
				:rowKey="record=>record.id" :scroll="{ x: 1400,y:500 }" size="middle" tableKey="view-text-table"
				@change="handleTableChange" @refresh="getList"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,fixed:true }"
				:format-conditions="true" :pagination="{
			  current: queryParam.pageNum,
			  pageSize: queryParam.pageSize,
			  total: total,
			  showSizeChanger: true,
			  showLessItems: true,
			  showQuickJumper: true,
			  showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条，总计 ${total} 条`,
			  onChange: changeSize,
			  onShowSizeChange: onShowSizeChange,
			}">
				<span slot="details" slot-scope="{details, record}">
					{{record.pcblength}}x{{record.pcbwidth}}cm,{{dataList.plateTG[record.pcbbrand-1].name}},
					{{dataList.numberBoardLayers[record.pcblayer-1].name}},{{dataList.minimumThroughHole[record.pcbminhole-1].name}},
					{{dataList.resistanceWeldingColor[record.pcbholecolor-1].name}},{{dataList.solderPadSprayCoating[record.pcbholespray-1].name}},
					{{dataList.copperThickness[record.pcbcuthick-1].name}}
				</span>
				<span slot="operation" slot-scope="{text, record}" style="display: flex;justify-content: center;">
					
				</span>
			</AdvanceTable> -->
		</div>
	</div>
</template>

<script>
	import AdvanceTable from '@/components/pt/table/AdvanceTable' //table组件引入
	import CButton from '@/components/CButton/index.vue' //button组件引入
	import moment from 'moment'
	import Cookies from "js-cookie";
	export default {
		components: {
			AdvanceTable,
			CButton
		},
		data() {
			return {
				panes: [{
					title: 'PCB',
					key: 1
				}, {
					title: 'SMT',
					key: 2
				}, {
					title: '钢网',
					key: 3
				}, {
					title: 'BOM',
					key: 4
				}],
				orderstatus:1,//订单状态
				dataList:[],
				columns: [
					{
						title: '详情',
						scopedSlots: {
							customRender: 'details'
						},
						width: 400,
					},
					{
						title: '数量',
						dataIndex: 'pcbcount',
						width: 150,
						// align: 'center'
					},
					{
						title: '快递类型',
						dataIndex: 'delivertype',
						width: 350,
						// align: 'center'
						scopedSlots: {
							customRender: 'delivertype'
						},
					},
					{
						title: '交货地址',
						dataIndex: 'address',
						width: 300,
						// align: 'center'
					},
					// {
					// 	title: '文件名',
					// 	dataIndex: 'fileName',
					// 	width: 200,
					// },
					{
						title: '订单总价',
						dataIndex: 'pcbprice',
						sorter: (a, b) => a.pcbprice > b.pcbprice,
						width: 150,
						ellipsis: true,
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: 250,
						fixed: 'right',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
			}
		},
		
		created() {
			var a = Cookies.get("addShoppingCart")
			if(a){
				var b = JSON.parse(a)
				b.map(res=>{
					if(res.type==this.orderstatus){
						if(this.orderstatus==1){
							let arr = {
								...res.pcbInfo[0],
								...res.pcbOderInfo
							}
							this.dataList.push(arr)
						}
					}
				})
			}
			console.log(this.dataList)
		},
		
		methods: {
			//----------------------tabs回调-------------------------------
			tabsChange: function(event) {
				console.log(event)
			},
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .ant-tabs-ink-bar {
		background-color: #f2ab15 !important;
	}
	
	/deep/ .ant-tabs-tab:hover {
		color: #f2ab15 !important;
	}
	
	/deep/ .ant-tabs-bar {
		margin: 0 !important;
	}
	
	/deep/ .ant-tabs-tab-active {
		color: #f2ab15 !important;
	}
	
</style>